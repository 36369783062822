<template>
  <div>
    <t-modal v-model="deleteFileDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Delete file</h4>
        <h4 class="text-lg text-red-500">
          You are about to delete this {{ selectedFile.title }}, are you sure?
        </h4>
        <div class="flex mt-5 w-full justify-between items-center">
          <div>
            <t-button
              @click="deleteFile(selectedFile._id)"
              class="flex space-x-3 items-center"
              ><span>Delete</span>
              <spinner v-if="deletingFiles"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <t-modal v-model="deleteCasenoteDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Delete Case Note</h4>
        <h4 class="text-base mt-4 text-red-500">
          You are about to delete this case note, are you sure?
        </h4>
        <p></p>
        <div class="flex mt-5 w-full justify-between items-center">
          <div>
            <t-button
              @click="deleteCasenote()"
              class="flex items-center  space-x-3 border w-28 border-accent px-3 py-1 rounded text-accent"
              variant="error"
              ><span>Delete</span>
              <spinner color="text-accent" v-if="deletingCasenote"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <div id="printCase" class="printing hidden">
      <div class="bg-white py-4 px-5">
        <h3
          class="font-bold text-center text-2xl flex justify-center space-x-2"
        >
          <img class="w-6 h-6" src="../../assets/images/brand.png" alt="" />
          <span> Jonathan Kings Ltd.</span>
        </h3>
        <p class="text-sm text-center">
          1st & 6th Floor, Cocoa House, Dugbe, Ibadan
        </p>
        <p class="text-sm text-center italics">
          08055060601, 08055060605, 08142427903
        </p>
        <p class="uppercase font-semibold text-sm text-center mt-5">
          CONSULTANT ADVICE &amp; Remarks
        </p>
        <div class="grid grid-cols-3 gap-5">
          <p class="text-sm font-semibold my-1">
            <span class="font-normal">Client's Name</span>
            <br />
            <span class=""
              >{{ selectedCaseNote.client.firstName }}
              {{ selectedCaseNote.client.lastName }}</span
            >
          </p>
          <p class="text-sm font-semibold my-1">
            <span class="font-normal">Date</span><br />
            <span class="">{{ new Date().toDateString() }}</span>
          </p>
          <p class="text-sm font-semibold my-1">
            <span class="font-normal">Program</span> <br />
            <span class="">{{
              selectedCaseNote.applicationType === 'visiting'
                ? 'Visiting Visa'
                : selectedCaseNote.applicationType === 'student'
                ? 'Study Visa'
                : selectedCaseNote.applicationType === 'skilled-worker'
                ? 'Skilled Worker'
                : selectedCaseNote.applicationType === 'skilled-trade'
                ? 'Skilled Trade'
                : ''
            }}</span>
          </p>
          <p class="text-sm font-semibold my-1">
            <span class="font-normal"> Country of Interest</span> <br />
            <span class="">{{ selectedCaseNote.countryOfInterest }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="
              selectedCaseNote.applicationType === 'student' ||
                selectedCaseNote.applicationType === 'skilled-worker'
            "
          >
            <span class="font-normal"> Current Education Level</span> <br />
            <span class="">{{ selectedCaseNote.currentEducationLevel }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="selectedCaseNote.applicationType === 'student'"
          >
            <span class="font-normal"> Education pursuit</span> <br />
            <span class="">{{ selectedCaseNote.educationalPursuit }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="selectedCaseNote.applicationType === 'skilled-worker'"
          >
            <span class="font-normal"> Number of Children</span> <br />
            <span class="">{{ selectedCaseNote.numberOfChildren }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="selectedCaseNote.applicationType === 'skilled-worker'"
          >
            <span class="font-normal"> Marital Status</span> <br />
            <span class="">{{ selectedCaseNote.maritalStatus }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="
              selectedCaseNote.applicationType === 'skilled-worker' ||
                selectedCaseNote.applicationType === 'skilled-trade'
            "
          >
            <span class="font-normal"> Years of Experience</span> <br />
            <span class="">{{ selectedCaseNote.yearsOfExperience }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="selectedCaseNote.applicationType === 'skilled-worker'"
          >
            <span class="font-normal"> Number of Family</span> <br />
            <span class="">{{ selectedCaseNote.numberOfFamily }}</span>
          </p>
          <p
            class="text-sm font-semibold my-1"
            v-if="
              selectedCaseNote.applicationType === 'skilled-worker' ||
                selectedCaseNote.applicationType === 'skilled-trade'
            "
          >
            <span class="font-normal"> NOC Code</span> <br />
            <span class="">{{ selectedCaseNote.nocCode }}</span>
          </p>
        </div>

        <div
          id="visiting"
          v-if="selectedCaseNote.applicationType === 'visiting'"
        >
          <ul>
            <li
              v-for="(n, l) in selectedCaseNote.clientTodo"
              :key="l"
              class="flex items-center justify-between text-sm py-2 border-b border-primary-light"
            >
              <div>
                <span class="font-serif text-xs text-gray-500 block">
                  {{
                    n['hasSecondarySchoolEducation']
                      ? 'Secondary School Education'
                      : n['hasCollegeEducation']
                      ? 'College Education'
                      : n['hasIELTSRequirement']
                      ? 'IELTS Requirement'
                      : n['hasEmploymentLetter']
                      ? 'Employment Letter'
                      : n['meetsSelectionFactors']
                      ? 'Selection Factor'
                      : n['hasFederalTradeTest']
                      ? 'Federal Trade Test'
                      : n['hasAnyCompanyTraining']
                      ? 'Any Company Training'
                      : n['hasApprenticeshipCert']
                      ? 'Apprenticeship Certificate'
                      : n['hasWorkExperience']
                      ? 'Work Experience'
                      : n['hasEducationCredAssessment']
                      ? 'Education Credentials'
                      : n['hasProofOfFunds']
                      ? 'Proof of Funds'
                      : ''
                  }}
                </span>

                {{
                  n['hasSecondarySchoolEducation'] ||
                    n['hasCollegeEducation'] ||
                    n['hasIELTSRequirement'] ||
                    n['hasEmploymentLetter'] ||
                    n['meetsSelectionFactors'] ||
                    n['hasFederalTradeTest'] ||
                    n['hasApprenticeshipCert'] ||
                    n['hasAnyCompanyTraining'] ||
                    n['hasWorkExperience'] ||
                    n['hasEducationCredAssessment'] ||
                    n['hasProofOfFunds']
                }}
              </div>
            </li>
          </ul>
        </div>

        <div
          id="student"
          class="mt-5 grid grid-cols-2 gap-5"
          v-if="selectedCaseNote.applicationType === 'student'"
        >
          <div>
            <h4 class="text-sm mb-4 font-semibold">
              Required Document for Admission
            </h4>
            <div class="grid md:grid-cols-2 grid-cols-1">
              <div
                v-for="n in selectedCaseNote.requiredAdmissionDocuments"
                :key="n.document"
              >
                <div class="flex items-start space-x-2">
                  <t-checkbox type="checkbox" v-model="n.isAvailable" />
                  <div class="flex flex-col">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="hasSecondarySchoolEducation"
                      >{{ n.document }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4 class="text-sm mb-4 font-semibold">
              Required Document for Study Permit Visa Application
            </h4>
            <div class="grid md:grid-cols-2 grid-cols-1">
              <div
                v-for="n in selectedCaseNote.requiredVisaApplicationDocuments"
                :key="n.document"
              >
                <div class="flex items-start space-x-2">
                  <t-checkbox type="checkbox" v-model="n.isAvailable" />
                  <div class="flex flex-col">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >{{ n.document }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-sm font-semibold text-gray-800 mb-2 block mt-4">
            Consultant Advice
          </p>
          <ul>
            <li
              v-for="(n, l) in selectedCaseNote.clientTodo"
              :key="l"
              class="flex items-center justify-between text-sm py-2 border-b border-primary-light"
            >
              <div>
                <span class="font-serif text-xs text-gray-500 block">
                  {{
                    n['hasSecondarySchoolEducation']
                      ? 'Secondary School Education'
                      : n['hasCollegeEducation']
                      ? 'College Education'
                      : n['hasIELTSRequirement']
                      ? 'IELTS Requirement'
                      : n['hasEmploymentLetter']
                      ? 'Employment Letter'
                      : n['meetsSelectionFactors']
                      ? 'Selection Factor'
                      : n['hasFederalTradeTest']
                      ? 'Federal Trade Test'
                      : n['hasAnyCompanyTraining']
                      ? 'Any Company Training'
                      : n['hasApprenticeshipCert']
                      ? 'Apprenticeship Certificate'
                      : n['hasWorkExperience']
                      ? 'Work Experience'
                      : n['hasEducationCredAssessment']
                      ? 'Education Credentials'
                      : n['hasProofOfFunds']
                      ? 'Proof of Funds'
                      : ''
                  }}
                </span>

                {{
                  n['hasSecondarySchoolEducation'] ||
                    n['hasCollegeEducation'] ||
                    n['hasIELTSRequirement'] ||
                    n['hasEmploymentLetter'] ||
                    n['meetsSelectionFactors'] ||
                    n['hasFederalTradeTest'] ||
                    n['hasApprenticeshipCert'] ||
                    n['hasAnyCompanyTraining'] ||
                    n['hasWorkExperience'] ||
                    n['hasEducationCredAssessment'] ||
                    n['hasProofOfFunds']
                }}
              </div>
            </li>
          </ul>
        </div>

        <div
          id="skilled-worker"
          v-if="selectedCaseNote.applicationType === 'skilled-worker'"
          class="mt-5"
        >
          <table class="table border-collapse table-fixed">
            <thead class="text-sm text-left">
              <th>Eligibility Requirement for Application</th>
              <th>Meet requirement?</th>
              <th>What need to meet the requirement</th>
            </thead>
            <tbody>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Work Experience</h4>
                  <div class="mt-3">
                    <p class="text-xs">
                      You'll need a minimum of 1 year experience within the last
                      last 10 years of year occupation
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasWorkExperience ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasWorkExperience')"
                        >{{ todo.hasWorkExperience }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">English Language Skills</h4>
                  <div class="mt-1">
                    <p class="text-sm">
                      <span class="text-xs text-gray-500"
                        >Test results of IELTS (International English Languauge
                        Testing System), which shows the client have scored at
                        the following Minimum level
                        <br />
                        <b
                          >Listening 6.0, Speaking 6.0, Reading 6.0, Writing
                          6.0</b
                        >
                      </span>
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasIELTSRequirement ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasIELTSRequirement')"
                        >{{ todo.hasIELTSRequirement }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">
                    Educational Credential Assessment
                  </h4>
                  <div class="mt-1">
                    <p class="text-sm">
                      <span class="text-xs text-gray-500"
                        >Clients foreign educational Credential i.e degree,
                        diploma or certificate must be assessed by one of the
                        organization designated by IRCC to be valid and equal to
                        a completed credentials in Canada
                      </span>
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{
                    selectedCaseNote.hasEducationCredAssessment ? 'Yes' : 'No'
                  }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="
                          Object.keys(todo).includes(
                            'hasEducationCredAssessment'
                          )
                        "
                        >{{ todo.hasEducationCredAssessment }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Selection Factor</h4>
                  <div class="mt-1 grid grid-cols-3">
                    <p
                      v-for="n in selectedCaseNote.selectionFactors"
                      :key="n._id"
                      class="text-sm"
                    >
                      <span class="text-xs text-gray-500">
                        {{ n.factor }}
                      </span>
                      <br />
                      <span class="text-sm">
                        {{ n.clientScore }}/{{ n.maxScore }}
                      </span>
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{
                    selectedCaseNote.selectionFactors[6].clientScore >= 67
                      ? 'Yes'
                      : 'No'
                  }}
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Proof of Funds</h4>
                  <div class="mt-1">
                    <p class="text-sm">
                      <!-- <span class="text-xs text-gray-500"
                        >If client have an arranged employment in Canada,then
                        they must show concrete proof
                      </span> -->
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasProofOfFunds ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasProofOfFunds')"
                        >{{ todo.hasProofOfFunds }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          id="skilled-trade"
          v-if="selectedCaseNote.applicationType === 'skilled-trade'"
          class="mt-5"
        >
          <table class="table border-collapse table-fixed">
            <thead class="text-sm text-left">
              <th>
                Qualification and/or Document You need to be Eligible to Apply
              </th>
              <th>Meet requirement?</th>
              <!-- <th>What need to meet the requirement</th> -->
            </thead>
            <tbody>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Education</h4>
                  <div class="mt-3">
                    <p class="text-sm font-semibold">Secondary School</p>
                    <p class="text-xs">
                      Successful Completion WAEC, NECO, GCE, with at least 6
                      Credits including English, Maths and 1 Science Subject
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{
                    selectedCaseNote.hasSecondarySchoolEducation ? 'Yes' : 'No'
                  }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="
                          Object.keys(todo).includes(
                            'hasSecondarySchoolEducation'
                          )
                        "
                        >{{ todo.hasSecondarySchoolEducation }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <div class="">
                    <p class="text-sm font-semibold">College</p>
                    <p class="text-xs">
                      Certificate, Diploma or Higher Diploma in your occupation
                      e.g City &amp; Guilds Certificate, NABTEB etc
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasCollegeEducation ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasCollegeEducation')"
                        >{{ todo.hasCollegeEducation }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Trade Certificate</h4>
                  <div class="mt-3">
                    <p class="text-sm font-semibold">
                      Federal Trade Test up to Grade 1 Level
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasFederalTradeTest ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasFederalTradeTest')"
                        >{{ todo.hasFederalTradeTest }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <div class="">
                    <p class="text-sm font-semibold">
                      Apprenticeship Certificate
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasApprenticeshipCert ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="
                          Object.keys(todo).includes('hasApprenticeshipCert')
                        "
                        >{{ todo.hasApprenticeshipCert }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <div class="">
                    <p class="text-sm font-semibold">
                      Any other Company Training Certificate
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasAnyCompanyTraining ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="
                          Object.keys(todo).includes('hasAnyCompanyTraining')
                        "
                        >{{ todo.hasAnyCompanyTraining }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>

              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">English Language Skills</h4>
                  <div class="mt-1">
                    <p class="text-sm">
                      <span class="text-xs text-gray-500"
                        >Test results of IELTS (International English Languauge
                        Testing System),CLB Level 5 which shows the client have
                        scored at the following Minimum level
                        <br />
                        <b
                          >Listening 5.0, Speaking 5.0, Reading 3.5, Writing
                          4.0</b
                        >
                      </span>
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasIELTSRequirement ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasIELTSRequirement')"
                        >{{ todo.hasIELTSRequirement }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">Work Experience</h4>
                  <div class="mt-1">
                    <p class="text-sm">
                      <span class="text-xs text-gray-500"
                        >Minimum of 2-year experience within the last 5 years of
                        your occupation</span
                      >
                    </p>
                  </div>
                </td>
                <td class="font-bold">
                  {{ selectedCaseNote.hasWorkExperience ? 'Yes' : 'No' }}
                </td>
                <td>
                  <ul>
                    <li
                      v-for="(todo, n) in selectedCaseNote.clientTodo"
                      :key="n"
                    >
                      <span
                        v-if="Object.keys(todo).includes('hasWorkExperience')"
                        >{{ todo.hasWorkExperience }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class="py-2">
                <td>
                  <h4 class="text-sm font-bold">
                    Qualification in Client Trade
                  </h4>
                  <div class="mt-1">
                    <p class="text-sm flex flex-col">
                      <span class="text-xs text-gray-500"
                        >Employment letter from a Canadian Employer that you
                        have been offered full time employment for at least 1
                        year. You must challenge the qualification</span
                      >
                      <span class="text-xs text-gray-500">
                        Letter from a provincial Government in Canada starting
                        that client have work experience in there trader
                      </span>
                    </p>
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="text-sm font-semibold text-gray-800 mb-2 block mt-4">
          Consultant Advice to Clients
        </p>
        <div>
          <p
            class="text-sm my-3"
            v-for="(note, i) in selectedCaseNote.note"
            :key="i"
          >
            {{ note }}
          </p>
        </div>

        <p class="text-sm font-semibold text-gray-800 mb-2 block mt-4">
          Todo for Consultant
        </p>

        <ul class="flex flex-wrap space-x-5">
          <li
            v-for="(n, l) in selectedCaseNote.consultantTodo"
            :key="l"
            class="flex items-center justify-between text-sm py-2"
          >
            <div>
              <span class="font-serif text-xs text-gray-500 block">
                {{
                  n['hasSecondarySchoolEducation']
                    ? 'Secondary School Education'
                    : n['hasCollegeEducation']
                    ? 'College Education'
                    : n['hasIELTSRequirement']
                    ? 'IELTS Requirement'
                    : n['hasEmploymentLetter']
                    ? 'Employment Letter'
                    : n['meetsSelectionFactors']
                    ? 'Selection Factor'
                    : n['hasFederalTradeTest']
                    ? 'Federal Trade Test'
                    : n['hasAnyCompanyTraining']
                    ? 'Any Company Training'
                    : n['hasApprenticeshipCert']
                    ? 'Apprenticeship Certificate'
                    : n['hasWorkExperience']
                    ? 'Work Experience'
                    : n['hasEducationCredAssessment']
                    ? 'Education Credentials'
                    : n['hasProofOfFunds']
                    ? 'Proof of Funds'
                    : ''
                }}
              </span>

              {{
                n['hasSecondarySchoolEducation'] ||
                  n['hasCollegeEducation'] ||
                  n['hasIELTSRequirement'] ||
                  n['hasEmploymentLetter'] ||
                  n['meetsSelectionFactors'] ||
                  n['hasFederalTradeTest'] ||
                  n['hasApprenticeshipCert'] ||
                  n['hasAnyCompanyTraining'] ||
                  n['hasWorkExperience'] ||
                  n['hasEducationCredAssessment'] ||
                  n['hasProofOfFunds']
              }}
            </div>
          </li>
        </ul>

        <div class="sign mt-10">
          <div class="grid grid-cols-2 gap-5">
            <div>
              <hr />
              <p class="text-sm text-center mt-1 font-semibold">
                {{ selectedCaseNote.client.firstName }}
                {{ selectedCaseNote.client.lastName }}
              </p>
            </div>
            <div>
              <hr />
              <p class="text-sm text-center mt-1 font-semibold">
                {{ user.firstName }} {{ user.lastName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Printing -->
    <page-title>Client Profile</page-title>
    <div class="md:flex items-center justify-between">
      <page-subtitle>View client profile here</page-subtitle>
      <div class="mt-3 md:mt-0 flex space-x-2 items-center">
        <t-button :to="{ name: 'Case', params: { clientId } }"
          >Start Assessment</t-button
        >
        <t-button :to="{ name: 'EditClient', params: { id: clientId } }"
          >Edit Profile</t-button
        >
      </div>
    </div>
    <div class="mt-5">
      <div class="mb-5 bg-white">
        <div
          class="tabs flex flex-row space-x-3 text-gray-600 text-sm font-semibold border-b border-gray-200"
        >
          <span
            @click="changeActiveTab('profile')"
            class="py-3 px-5 cursor-pointer rounded-tl"
            :class="
              activeTab === 'profile' ? 'bg-primary text-white' : 'bg-white'
            "
            >Profile</span
          >
          <span
            @click="changeActiveTab('case')"
            class="py-3 px-5 cursor-pointer"
            :class="activeTab === 'case' ? 'bg-primary text-white' : 'bg-white'"
            >Case</span
          >
          <span
            @click="changeActiveTab('payment')"
            class="py-3 px-5 cursor-pointer"
            :class="
              activeTab === 'payment' ? 'bg-primary text-white' : 'bg-white'
            "
            >Payment</span
          >
          <span
            @click="changeActiveTab('documents')"
            class="py-3 px-5 cursor-pointer"
            :class="
              activeTab === 'documents' ? 'bg-primary text-white' : 'bg-white'
            "
            >Documents</span
          >
        </div>
      </div>
      <div id="profile" v-if="activeTab === 'profile'">
        <div class="bg-white p-5 rounded">
          <div class="flex flex-col">
            <section-subtitle> Client's Picture </section-subtitle>
            <div class="flex space-x-10">
              <avatar :src="userData.image || ''" size="xlarge" />
              <div class="content">
                <p class="content-text mb-1">
                  {{ userData.firstName }} {{ userData.lastName }}
                </p>
                <p class="text-gray-500 text-sm lowercase mb-1">
                  {{ userData.email }}
                </p>
                <div class="flex space-x-4 text-gray-500 text-sm lowercase">
                  <span class="capitalize">{{ userData.gender }}</span>
                  <span class="capitalize"
                    >{{
                      new Date().getFullYear() -
                        new Date(userData.dateOfBirth).getFullYear()
                    }}
                    years old</span
                  >
                  <span class="capitalize">{{
                    userData.countryOfCitizenship
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white p-5 rounded mt-5">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-8">
            <div class="content">
              <p class="content-title">Full Name</p>
              <p class="content-text">
                {{ userData.firstName }} {{ userData.lastName }}
              </p>
            </div>
            <div class="content">
              <p class="content-title">Email</p>
              <p class="content-text">{{ userData.email }}</p>
            </div>
            <div class="content">
              <p class="content-title">
                Applicant Fullname
                <span class="content-explain">(as shown in passport)</span>
              </p>
              <p class="content-text">
                {{ userData.firstName }} {{ userData.lastName }}
              </p>
            </div>
            <div class="content">
              <p class="content-title">Gender</p>
              <p class="content-text">{{ userData.gender }}</p>
            </div>
            <div class="grid grid-cols-2">
              <div class="content">
                <p class="content-title">Place of Birth</p>
                <p class="content-text">{{ userData.placeOfBirth }}</p>
              </div>
              <div class="content">
                <p class="content-title">Date of Birth</p>
                <p class="content-text">{{ userData.dateOfBirth }}</p>
              </div>
            </div>
            <div class="content">
              <p class="content-title">Country of Citizenship</p>
              <p class="content-text">{{ userData.countryOfCitizenship }}</p>
            </div>
            <div class="content">
              <p class="content-title">Marital Status</p>
              <p class="content-text">{{ userData.maritalStatus }}</p>
            </div>
            <div class="content">
              <p class="content-title">
                Education
                <span class="content-details"
                  >Client's highest level of Education</span
                >
              </p>
              <p class="content-text">{{ userData.highestEducationLevel }}</p>
            </div>
            <div class="content">
              <p class="content-title">Current Occupation</p>
              <p class="content-text">{{ userData.occupation }}</p>
            </div>
            <div class="content">
              <p class="content-title">Phone Number</p>
              <p class="content-text">{{ userData.phone }}</p>
            </div>
            <div class="content">
              <p class="content-title">Residential Address</p>
              <p class="content-text">
                {{ userData.address }}
              </p>
            </div>
            <div class="content">
              <p class="content-title">Mailing Address</p>
              <p class="content-text">
                {{ userData.mailingAddress }}
              </p>
            </div>
            <div class="content">
              <p class="content-title">Country of Interest</p>
              <p class="content-text">{{ userData.countryOfInterest }}</p>
            </div>
            <div class="content">
              <p class="content-title">Purpose of Travel</p>
              <p class="content-text">{{ userData.purposeOfTraveling }}</p>
            </div>
            <div class="content">
              <p class="content-title">Form Pickup Location</p>
              <p class="content-text">{{ userData.pickupLocation }}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="case" v-if="activeTab === 'case'">
        <div
          v-for="n in caseNote"
          :key="n._id"
          class="bg-white shadow-md p-3 rounded my-4"
        >
          <div>
            <div
              class="md:flex items-center justify-between mt-5 flex-row space-x-3"
            >
              <span class="text-sm font-medium text-black">{{
                new Date(n.date).toDateString()
              }}</span>

              <hr class="mr-auto text-right md:block w-9/12 hidden" />
              <t-button
                :to="{ name: 'EditCase', params: { clientId, caseId: n._id } }"
                >Edit
              </t-button>
              <t-button @click="printWork(n)">Print </t-button>
            </div>
            <div
              class="flex mt-5 items-start flex-wrap justify-between space-x-5 space-y-5"
            >
              <div>
                <p class="text-xs font-medium text-gray-400 mb-1">Full Name</p>
                <p class="text-sm font-semibold text-gray-800">
                  {{ userData.firstName }} {{ userData.lastName }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-400 mb-1">Email</p>
                <p class="text-sm font-semibold text-gray-800">
                  {{ userData.email }}
                </p>
              </div>
              <div class="mr-5">
                <p class="text-xs font-medium text-gray-400 mb-1">Visa Type</p>
                <p class="text-sm font-semibold text-gray-800 capitalize">
                  {{
                    n.applicationType === 'visiting'
                      ? 'Visiting Visa'
                      : n.applicationType === 'student'
                      ? 'Study Visa'
                      : n.applicationType === 'skilled-worker'
                      ? 'Skilled Worker'
                      : n.applicationType === 'skilled-trade'
                      ? 'Skilled Trade'
                      : ''
                  }}
                </p>
              </div>
            </div>
            <div class="mt-5">
              <p class="text-xs font-medium text-gray-400 mb-1">Note</p>
              <p class="text-sm">
                {{ n.note }}
              </p>
            </div>
            <div class="mt-5 flex space-x-5">
              <div class="w-full">
                <p class="text-xs font-medium text-gray-400 mb-1">
                  Todo's for Clients
                </p>
                <ul class="text-sm">
                  <li v-for="(n, i) in n.clientTodo" :key="i">
                    <div>
                      <span class="font-serif text-xs text-gray-500 block">
                        {{
                          n['hasSecondarySchoolEducation']
                            ? 'Secondary School Education'
                            : n['hasCollegeEducation']
                            ? 'College Education'
                            : n['hasIELTSRequirement']
                            ? 'IELTS Requirement'
                            : n['hasEmploymentLetter']
                            ? 'Employment Letter'
                            : n['meetsSelectionFactors']
                            ? 'Selection Factor'
                            : n['hasFederalTradeTest']
                            ? 'Federal Trade Test'
                            : n['hasAnyCompanyTraining']
                            ? 'Any Company Training'
                            : n['hasApprenticeshipCert']
                            ? 'Apprenticeship Certificate'
                            : n['hasWorkExperience']
                            ? 'Work Experience'
                            : n['hasEducationCredAssessment']
                            ? 'Education Credentials'
                            : n['hasProofOfFunds']
                            ? 'Proof of Funds'
                            : ''
                        }}
                      </span>

                      {{
                        n['hasSecondarySchoolEducation'] ||
                          n['hasCollegeEducation'] ||
                          n['hasIELTSRequirement'] ||
                          n['hasEmploymentLetter'] ||
                          n['meetsSelectionFactors'] ||
                          n['hasFederalTradeTest'] ||
                          n['hasApprenticeshipCert'] ||
                          n['hasAnyCompanyTraining'] ||
                          n['hasWorkExperience'] ||
                          n['hasEducationCredAssessment'] ||
                          n['hasProofOfFunds']
                      }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="w-full">
                <p class="text-xs font-medium text-gray-400 mb-1">
                  Todo's for Consultant
                </p>
                <ul class="text-sm">
                  <li v-for="(n, i) in n.consultantTodo" :key="i">
                    <div>
                      <span class="font-serif text-xs text-gray-500 block">
                        {{
                          n['hasSecondarySchoolEducation']
                            ? 'Secondary School Education'
                            : n['hasCollegeEducation']
                            ? 'College Education'
                            : n['hasIELTSRequirement']
                            ? 'IELTS Requirement'
                            : n['hasEmploymentLetter']
                            ? 'Employment Letter'
                            : n['meetsSelectionFactors']
                            ? 'Selection Factor'
                            : n['hasFederalTradeTest']
                            ? 'Federal Trade Test'
                            : n['hasAnyCompanyTraining']
                            ? 'Any Company Training'
                            : n['hasApprenticeshipCert']
                            ? 'Apprenticeship Certificate'
                            : n['hasWorkExperience']
                            ? 'Work Experience'
                            : n['hasEducationCredAssessment']
                            ? 'Education Credentials'
                            : n['hasProofOfFunds']
                            ? 'Proof of Funds'
                            : ''
                        }}
                      </span>

                      {{
                        n['hasSecondarySchoolEducation'] ||
                          n['hasCollegeEducation'] ||
                          n['hasIELTSRequirement'] ||
                          n['hasEmploymentLetter'] ||
                          n['meetsSelectionFactors'] ||
                          n['hasFederalTradeTest'] ||
                          n['hasApprenticeshipCert'] ||
                          n['hasAnyCompanyTraining'] ||
                          n['hasWorkExperience'] ||
                          n['hasEducationCredAssessment'] ||
                          n['hasProofOfFunds']
                      }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div class="flex mt-5 w-full justify-between items-center">
                <div>
                  <t-button
                    @click="selectCasenote(n._id)"
                    class="flex items-center border text-sm border-accent px-3 py-1 rounded text-accent"
                    variant="error"
                    ><span>Delete Note</span>
                  </t-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="payment" v-if="activeTab === 'payment'">
        <div class="payment-list grid grid-cols-1 mt-5">
          <div class="flex flex-col w-full">
            <div class="overflow-x-scroll">
              <table class="table-fixed min-h-full min-w-full">
                <thead
                  class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                >
                  <tr>
                    <th class="py-3 px-3">Client's Name</th>
                    <th class="py-3 px-3">Title</th>
                    <th class="py-3 px-3">Date Created</th>
                    <th class="py-3 px-3">Amount</th>
                    <th class="py-3 px-3">Amount Paid</th>
                    <th class="py-3 px-3">Amount Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="invoice in invoices"
                    @click="
                      () => {
                        $router.push({
                          name: 'PaymentList',
                          params: { invoiceId: invoice._id }
                        })
                      }
                    "
                    :key="invoice._id"
                    class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                  >
                    <td class="px-3 py-4">
                      {{ invoice.client.firstName }}
                      {{ invoice.client.lastName }}
                    </td>
                    <td class="px-3 py-4">{{ invoice.title }}</td>
                    <td class="px-3 py-4">
                      {{ new Date(invoice.createdAt).toDateString() }}
                    </td>
                    <td class="px-3 py-4">{{ invoice.amount }}</td>
                    <td class="px-3 py-4">{{ invoice.totalAmountPaid }}</td>
                    <td class="px-3 py-4">
                      {{
                        Number(invoice.amount) - Number(invoice.totalAmountPaid)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="documents" v-if="activeTab === 'documents'">
        <div class="bg-white py-4 px-5">
          <div class="upload">
            <p class="text-center" v-if="file !== null">
              Uploaded file: <b>{{ fileName }}</b>
            </p>
            <div class="flex justify-center">
              <label
                for="document-upload"
                class="border-dotted border rounded-md border-primary hover:border-amber-500 text-sm py-1 px-5 cursor-pointer"
              >
                <input
                  @change="changeDoc"
                  type="file"
                  class="hidden"
                  name="document-upload"
                  id="document-upload"
                />
                {{
                  file === null ? 'Click here to choose a file' : 'Change file'
                }}
              </label>
            </div>
            <div class="flex items-center justify-end mt-5">
              <t-button
                class="flex space-x-4 items-center justify-start"
                @click="uploadDocument"
                ><label v-if="!uploadingDoc">Upload</label>
                <spinner v-else></spinner>
              </t-button>
            </div>
          </div>
          <div class="mt-5">
            <h3 class="text-sm font-semibold">Document</h3>
            <div class="grid md:grid-cols-3 gap-5 grid-cols-1">
              <div
                v-for="d in documents"
                :key="d._id"
                class="py-3 px-2 rounded-md shadow flex flex-col space-y-2"
              >
                <span
                  class="whitespace-pre-wrap"
                  target="_blank"
                  :href="d.url"
                  download=""
                  >{{ d.title }}</span
                >
                <hr class="px-2" />
                <div class="flex justify-between items-end">
                  <a
                    class="text-sm text-primary"
                    target="_blank"
                    :href="d.url"
                    download=""
                    >Download</a
                  >
                  <span
                    @click="deleteAFile(d)"
                    class="text-xs text-red-500 cursor-pointer"
                    >Delete</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle.vue'
import Avatar from '../../components/Utils/Avatar.vue'
import clientApi from '../../api/client'
import caseApi from '../../api/case'
import invoiceApi from '../../api/invoice'
import { mapState } from 'vuex'
import Spinner from '../../components/Utils/Spinner.vue'

export default {
  name: 'ClientProfile',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Avatar, Spinner },
  data: () => ({
    activeTab: 'profile',
    clientId: '',
    userData: {},
    caseNote: [],
    documents: [],
    invoices: [],
    file: null,
    deleteFileDialog: false,
    selectedFile: {},
    deletingFiles: false,
    uploadingDoc: false,
    fileName: '',
    selectedCaseNote: {
      client: {}
    },
    deletingCasenote: false,
    deleteCasenoteDialog: false,
    selectedCasenoteID: ''
  }),
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created() {
    this.clientId = this.$route.params.id
    this.activeTab = this.$route.query.tab || 'profile'
    this.getProfile()
    this.getCaseNote()
    this.getInvoices()
    this.getDocuments()
  },
  methods: {
    async deleteCasenote() {
      this.deletingCasenote = true
      const res = await caseApi
        .caseNote()
        .delete({ caseId: this.selectedCasenoteID })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingCasenote = false
        return
      }
      this.getCaseNote()
      this.deletingCasenote = false
      this.deleteCasenoteDialog = false

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: 'Case note deleted successfully'
      })
    },
    selectCasenote(id) {
      this.selectedCasenoteID = id
      this.deleteCasenoteDialog = true
    },
    deleteAFile(file) {
      this.selectedFile = file
      this.deleteFileDialog = true
    },
    changeActiveTab(tab) {
      this.activeTab = tab
    },
    changeDoc(e) {
      if (e.target.files === null) {
        this.file = null
        return
      }
      this.file = e.target.files[0]
      this.fileName = e.target.files[0].name
    },
    async getDocuments() {
      const res = await clientApi
        .client()
        .fetchDocument({ clientId: this.clientId })
      this.documents = res.data.data
    },
    async deleteFile(fileId) {
      this.deletingFiles = true
      const res = await clientApi.client().deleteDocument({ fileId })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingFiles = false
        return
      }
      this.getDocuments()
      this.selectedFile = {}
      this.deleteFileDialog = false
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
    },
    async uploadDocument() {
      if (this.file === null) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: 'Please select a document to upload'
        })
        return
      }
      this.uploadingDoc = true
      const res = await clientApi.client().uploadDocument({
        clientId: this.clientId,
        title: this.fileName.split('.')[0],
        file: this.file
      })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        return
      }
      this.file = null
      this.fileName = ''
      this.getDocuments()
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.uploadingDoc = false
    },
    async getInvoices() {
      const res = await invoiceApi.invoice().getAll({ offset: 0 })
      this.invoices = res.data.data.filter(
        d => d?.client?._id === this.clientId
      )
    },
    async getCaseNote() {
      const res = await caseApi.caseNote().getAll({
        client: this.clientId,
        offset: 0,
        populate: 'client'
      })
      this.caseNote = res.data.data
    },
    async getProfile() {
      const res = await clientApi
        .client()
        .getAClient({ clientId: this.clientId })
      this.userData = res.data
    },
    printWork(n) {
      this.selectedCaseNote = n

      setTimeout(() => {
        // Get HTML to print from element
        const prtHtml = document.getElementById('printCase').innerHTML

        // Get all stylesheets HTML
        let stylesHtml = ''
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style')
        ]) {
          stylesHtml += node.outerHTML
        }

        // Open the print window
        const WinPrint = window.open(
          '',
          '',
          'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
        )

        WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

        WinPrint.document.close()
        WinPrint.focus()
        WinPrint.print()
      }, 2500)
    }
  }
}
</script>

<style scoped, SectionSubtitle>
.content-title {
  @apply text-xs font-normal text-gray-500;
}

.content-text {
  @apply text-sm font-bold text-gray-900 mt-2 capitalize;
}

.content-explain {
  @apply text-accent font-light;
}
</style>
