import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/invoices`

export default {
  invoice() {
    return {
      create: async ({ title, clientId, amount }) => {
        const endpoint = `${URL}`
        const data = { title, clientId, amount }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      update: async ({ invoiceId }, data) => {
        const endpoint = `${URL}/${invoiceId}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAll: async ({ offset }) => {
        const endpoint = `${URL}?populate=client&offset=${offset}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAllWithLimit: async ({ limit = 0 }) => {
        const endpoint = `${URL}?populate=client&limit=${limit}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getOne: async ({ invoiceId }) => {
        const endpoint = `${URL}/${invoiceId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      editInvoice: async ({ invoiceId, data }) => {
        console.log(data)
        const endpoint = `${URL}/${invoiceId}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteInvoice: async ({ invoiceId }) => {
        const endpoint = `${URL}/${invoiceId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      createPayment: async ({ invoiceId }, { amount, paidAt, title }) => {
        const endpoint = `${URL}/${invoiceId}/payments`
        const data = { amount, paidAt, title }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      editPayment: async ({ paymentId }, { amount, title }) => {
        const endpoint = `${URL}/payments/${paymentId}`
        const data = { amount, title }
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getPayment: async ({ paymentId }) => {
        const endpoint = `${URL}/payments/${paymentId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getPayments: async ({ invoiceId }) => {
        const endpoint = `${URL}/payments?invoiceId=${invoiceId}&populate=client&limit=0`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deletePayment: async ({ paymentId }) => {
        const endpoint = `${URL}/payments/${paymentId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
