<template>
  <h4 class="font-normal -mt-1 text-sm text-gray-400">
    <slot> </slot>
  </h4>
</template>

<script>
export default {
  name: 'PageSubtitle'
}
</script>

<style></style>
