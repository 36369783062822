<template>
  <h1 class="mt-6 mb-3 md:text-2xl text-xl font-semibold text-gray-800">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'PageTitle'
}
</script>

<style></style>
